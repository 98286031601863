/* modified from https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/ */

.file-uploader {
  width: fit-content;
  color: black;
}

.file-uploader input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-uploader label {
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.file-uploader .text {
  padding-right: 0.5rem;
  font-size: 1rem;
}

/* BUTTONS */
.button {
  transition: background-color 0.3s;
  font-weight: bold;
  border: none;
  border-radius: 0.5rem;
}

.button:hover {
  cursor: pointer;
}

.button:focus {
  /* not accessible via keyboard, but you need mouse to draw anyway */
  outline: none;
}

.button-red {
  background-color: #e74c3c;
}

.button-red:hover,
.button-red:active {
  background-color: #c13f32;
}

.button-blue {
  background-color: #3498db;
  font-size: 1.5rem;
}

.button-blue:hover,
.button-blue:active {
  background-color: #318cc7;
}

/* .button-blue:hover,
.button-blue:active {
  background-color: #2980b9;
} */
